import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { NgxPaginationModule } from "ngx-pagination";
import { AppGerarPDF } from "src/app/app.gerapdf";
import { Touro } from "src/app/core/models/touro";
import { TourosService } from "src/app/core/services/touros.service";
import { DadosEmpresaComponent } from "src/app/shared/components/dados-empresa.component";
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AppGlobals } from "src/app/app.globals";

@Component({
  standalone: true,
  selector: 'app-touros',
  imports: [CommonModule, FormsModule, IonicModule, NgxPaginationModule, DadosEmpresaComponent],
  templateUrl: './meus-touros.component.html',
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }]
})
export class MeusTourosComponent implements OnInit {
  touros: Touro[] = [];
  tourosFiltro: Touro[] = [];
  nomeTouro: string = '';
  isLoading: boolean = false;
  apenasCentral: boolean = true;
  listaLojas: string[] = [];
  lojaSelecionada: string = 'Todas';
  listaStatusTouro: string[] = [];
  statusSelecionado: string = 'Todos';
  page: number = 1;
  pageCount: number = 0;
  blnHomologacao: boolean = false;
  tempoDemora: string = "Tempo resposta api: ";

  constructor(private tourosService: TourosService) { }

  async ngOnInit() {
    this.isLoading = true;

    this.tourosService.tempoDemora$.subscribe((tempo) => {
      this.tempoDemora = tempo;
    });
  
    this.touros = await this.tourosService.consultarTouros();
    this.tourosFiltro = this.touros;

    this.listaLojas = [...new Set(this.touros.map(gestao => gestao.loja))].sort();

    this.listaStatusTouro = [...new Set(this.touros.filter(x => x.status != "").map(gestao => gestao.status))].sort();

    this.filtrarTouros();

    this.blnHomologacao = AppGlobals.blnHomologacao;

    this.isLoading = false;
  }

  filtrarTouros() {
    this.isLoading = true;

    setTimeout(() => {
      this.tourosFiltro = this.touros.filter(x => x.nome.toUpperCase().includes(this.nomeTouro.toUpperCase()));

      if (this.apenasCentral)
        this.tourosFiltro = this.tourosFiltro.filter(x => x.apenasCentral);

      if (this.lojaSelecionada != "" && this.lojaSelecionada != "Todas")
        this.tourosFiltro = this.tourosFiltro.filter(x => x.loja == this.lojaSelecionada);

      if (this.statusSelecionado != "" && this.statusSelecionado != "Todos")
        this.tourosFiltro = this.tourosFiltro.filter(x => x.status == this.statusSelecionado);

      this.page = 1;

      this.isLoading = false;
    }, 500);
  }

  gerarPDF() {

    if (this.tourosFiltro.length == 0)
      return;

    const doc = new jsPDF();
    const intInicioY = 30;
    const alturaLinha = 5;
    const tituloRelatorio = 'Relatório de Meus Touros na Central';
    const headers = ['Touro', 'Nome Guerra', 'Dt Entrada', 'Peso Entrada', 'Ini. Quarentena', 'Prev.Entr.Coleta', 'Fim Quarentena', 'Dt Saída', 'Status'];
    const propriedades = ['codigo', 'nome', 'dataEntrada', 'pesoEntrada', 'inicioQuarentena', 'prevEntradaColeta', 'fimQuarentena', 'dataSaida', 'status'];
    const columnWidths = [15, 40, 18, 19, 21, 22, 22, 18, 20];
    const totalItensPrim = 50;
    const totalItensProx = 54;

    AppGerarPDF.gerarPDF_AdicionandoTitulo(doc, tituloRelatorio);
    AppGerarPDF.gerarPDF_AdicionandoDados(doc, intInicioY, alturaLinha, totalItensPrim, totalItensProx, columnWidths, headers, this.tourosFiltro, propriedades);
    AppGerarPDF.gerarPDF_AdicionandoRodape(doc, columnWidths);

    doc.save('MeusTouros.pdf');
  }
}