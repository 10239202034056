import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.globals';
import { faEye } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  usuario: string = '';
  senha: string = '';
  errorMessage: string | null = null;
  isLoading: boolean = false;
  isPasswordVisible: boolean = false;
  faEye = faEye;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void { 
    localStorage.setItem('authUsuario', '');
    localStorage.setItem('authNome', '');
    localStorage.setItem('authCNPJ', '');
    localStorage.setItem('authStore', '');
    localStorage.setItem('authSenha', '');
    localStorage.setItem('authDataBearer', '');
    localStorage.setItem('authSigla', '');
    this.authService.getAccessToken();
  }

  visualizandoSenha(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  async onLogin(): Promise<void> {
    this.isLoading = true;
    //this.usuario = "00000101";
    //this.senha = "XCT259X2";
    //this.usuario = "00028501"; 
    //this.senha = "RCZ160Y6";

    const usuarioLogin = this.usuario;
    let codigoUsuario = "";
    let lojaUsuario = "";

    if (usuarioLogin.length == 8){
      codigoUsuario = this.usuario.substring(0, 6); 
      lojaUsuario = this.usuario.substring(6);
    }
    if (usuarioLogin.length <= 6){
      codigoUsuario = this.usuario.padStart(6, '0'); 
      lojaUsuario = '01';
    }

    AppGlobals.STORE = lojaUsuario;    

    try {
      const response = await this.authService.login(codigoUsuario, this.senha);

      AppGlobals.exibindoLogs('Login bem-sucedido: ' + response);

      localStorage.setItem('authUsuario', codigoUsuario);
      localStorage.setItem('authStore', lojaUsuario);      
      localStorage.setItem('authSenha', this.senha);
      localStorage.setItem('authNome', response.name);
      localStorage.setItem('authCNPJ', response.cnpj);
      localStorage.setItem('authSigla', AppGlobals.simplificarNome(response.name));

      this.router.navigate(['/home']);

    } catch (error) {
      console.error('Erro de login:', JSON.stringify(error, null, 2));
      this.errorMessage = 'Usuário e/ou senha inválidos';

    } finally {
      this.isLoading = false; 
    }
  }
}
