import { Component, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

@Component({
  standalone: true,
  selector: 'app-dados-empresa',
  templateUrl: './dados-empresa.component.html',
  styleUrls: ['./dados-empresa.component.scss'],
  imports: [CommonModule, FormsModule, IonicModule]
})
export class DadosEmpresaComponent implements OnInit {

  nome: string = '';
  cnpj: string = '';

  constructor() { }

  isDrawerContentVisible: boolean = true;

  checkScreenWidth() {
    this.isDrawerContentVisible = window.innerWidth >= 1000;
  }

  async ngOnInit() {
    this.nome = localStorage.getItem('authNome');
    this.cnpj = localStorage.getItem('authCNPJ');

    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth.bind(this));

  }

}
