import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import jsPDF from 'jspdf';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppGerarPDF } from 'src/app/app.gerapdf';
import { AppGlobals } from 'src/app/app.globals';
import { MovimentoEstoque } from 'src/app/core/models/movimento-estoque';
import { MovimentoEstoqueService } from 'src/app/core/services/movimento-estoque.service';
import { DadosEmpresaComponent } from "../../shared/components/dados-empresa.component";import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, NgxPaginationModule, DadosEmpresaComponent],
  selector: 'app-movimento-estoque',
  templateUrl: './movimento-estoque.component.html',
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }]
})
export class MovimentoEstoqueComponent implements OnInit {
  movimento: MovimentoEstoque[] = [];
  movimentoFiltro: MovimentoEstoque[] = [];
  contrato: string = '';
  isLoading: boolean = false;
  dtaIni: string = null;
  dtaFin: string = null;
  listaTouros: string[] = [];
  touroSelecionado: string = 'Todos';
  listaLojas: string[] = [];
  lojaSelecionada: string = 'Todas';
  listaMovimentos: string[] = [];
  movimentoSelecionado: string = 'Todos';
  page: number = 1;
  totalEntrada: number = 0;
  totalSaida: number = 0;
  totalEstoque: number = 0;
  blnHomologacao: boolean = false;
  tempoDemora: string = "Tempo resposta api: ";

  constructor(private movimentoEstoqueService: MovimentoEstoqueService) { }

  async ngOnInit() {
    this.isLoading = true;

    const dtaHoje = new Date();
    this.dtaIni = new Date(dtaHoje.setDate(dtaHoje.getDate() - AppGlobals.DIAS_FILTROS)).toISOString().split('T')[0];
    this.dtaFin = new Date().toISOString().split('T')[0];

    this.movimentoEstoqueService.tempoDemora$.subscribe((tempo) => {
      this.tempoDemora = tempo;
    });

    this.movimento = await this.movimentoEstoqueService.consultarMovimentoEstoque(this.dtaIni, this.dtaFin);
    this.movimento = this.movimento.sort((a, b) => { return b.dataDate.getTime() - a.dataDate.getTime(); });
    this.movimentoFiltro = [];

    this.calcularTotal();

    this.listaTouros = [...new Set(this.movimento.map(mov => `${mov.touro} - ${mov.nome_guerra}`))]
      .sort((a, b) => {
        const nomeA = a.split(' - ')[1].toLowerCase();
        const nomeB = b.split(' - ')[1].toLowerCase();
        return nomeA.localeCompare(nomeB);
      });

    this.listaLojas = [...new Set(this.movimento.map(gestao => gestao.loja))].sort();

    this.listaMovimentos = [...new Set(this.movimento.filter(x => x.descr_documento != "").map(gestao => gestao.descr_documento))].sort();

    this.filtrarMovimentos();

    this.blnHomologacao = AppGlobals.blnHomologacao;

    this.isLoading = false;
  }

  calcularTotal() {
    this.totalEntrada = this.movimentoFiltro.reduce((acc, movimento) => {
      return movimento.descr_operacao === "ENTRADA"
        ? acc + (Number(movimento.doses) || 0)
        : acc;
    }, 0);

    this.totalSaida = this.movimentoFiltro.reduce((acc, movimento) => {
      return movimento.descr_operacao === "SAIDA"
        ? acc + (Number(movimento.doses) || 0)
        : acc;
    }, 0);

    this.totalEstoque = this.totalEntrada - this.totalSaida;
  }

  async filtrarMovimentosData() {

    this.isLoading = true;

    this.movimentoEstoqueService.tempoDemora$.subscribe((tempo) => {
      this.tempoDemora = tempo;
    });

    this.movimento = await this.movimentoEstoqueService.consultarMovimentoEstoque(this.dtaIni, this.dtaFin);

    this.filtrarMovimentos();

    this.page = 1;

    this.isLoading = false;
  }

  filtrarMovimentos() {
    this.isLoading = true;

    setTimeout(() => {
      this.movimentoFiltro = this.movimento;

      if (this.touroSelecionado != "" && this.touroSelecionado != "Todos") {
        const partes = this.touroSelecionado.split(' - ');
        const contratoSelecionado = partes[0];
        const nomeTouroSelecionado = partes[1].toUpperCase();

        this.movimentoFiltro = this.movimento.filter(x =>
          x.touro.startsWith(contratoSelecionado) &&
          x.nome_guerra.toUpperCase().includes(nomeTouroSelecionado)
        );
      }

      if (this.lojaSelecionada != "" && this.lojaSelecionada != "Todas") {
        this.movimentoFiltro = this.movimentoFiltro.filter(x => x.loja == this.lojaSelecionada);
      }

      if (this.movimentoSelecionado != "" && this.movimentoSelecionado != "Todos") {
        this.movimentoFiltro = this.movimentoFiltro.filter(x => x.descr_documento == this.movimentoSelecionado);
      }

      this.calcularTotal();

      this.page = 1;

      this.isLoading = false;
    }, 500);
  }

  gerarPDF() {

    if (this.movimentoFiltro.length == 0)
      return;

    const doc = new jsPDF();
    const intInicioY = 30;
    const alturaLinha = 5;
    const tituloRelatorio = 'Relatório de Meus Touros na Central';

    const headers = [
      'Contrato', 'Touro', 'Nome Guerra', 'Tipo', 'Documento', 'Descrição', 'Data', 'Doses'
    ];
    
    const propriedades = [
      'contrato', 'touro', 'nome_guerra', 'descr_operacao', 'documento', 'descr_documento', 'data', 'doses'
    ];

    const totalizadoresHeader = [
      'Total Entrada: ' + this.totalEntrada.toFixed(2),
      'Total Saída: ' + this.totalSaida.toFixed(2),
      'Total em Estoque: ' + this.totalEstoque.toFixed(2)
    ]
    
    const columnWidths = [22, 17, 40, 20, 20, 40, 18, 10];

    const totalItensPrim = 50;
    const totalItensProx = 54;

    AppGerarPDF.gerarPDF_AdicionandoTitulo(doc, tituloRelatorio + ' - ' + this.touroSelecionado);
    AppGerarPDF.gerarPDF_AdicionandoDados(doc, intInicioY, alturaLinha, totalItensPrim, totalItensProx, columnWidths, headers, this.movimentoFiltro, propriedades, totalizadoresHeader);
    AppGerarPDF.gerarPDF_AdicionandoRodape(doc, columnWidths);

    doc.save('MovimentoEstoque' + this.touroSelecionado.replace(' - ', '-').replace(' ', '_') + '.pdf');
  }

}
