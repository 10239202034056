import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Touro } from "../models/touro";
import { AppGlobals } from "src/app/app.globals";
import { AuthService } from "src/app/auth.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TourosService {

  private tempoDemoraSource = new BehaviorSubject<string>('');
  tempoDemora$ = this.tempoDemoraSource.asObservable(); 

  constructor(private http: HttpClient, private authService: AuthService) {}

  async getHeadersAPI(usuario: string): Promise<HttpHeaders> {
    await this.authService.getAccessToken(); 
  
    return new HttpHeaders({
      'code': usuario,
      //'code': "00028501",
      'tenantid': AppGlobals.TENANT_ID,
      'Authorization': AppGlobals.AUTH_TOKEN
    });
  }

  async consultarTouros(): Promise<Touro[]> {
    try {
      const usuario = localStorage.getItem('authUsuario');
      AppGlobals.exibindoLogs("Touro Service Usuario: " + usuario);
  
      const headers = await this.getHeadersAPI(usuario);
  
      const urlApi = AppGlobals.API_URL + "/rest/meustouros/v1/touro/:cliente/touros";
      AppGlobals.exibindoLogs("Touro Service URL Final: " + urlApi);

      const startTime = new Date().getTime();
  
      const response = await this.http.get<{ code: number, message: string, dados: any[] }>(urlApi, { headers: headers, observe: 'response' }).toPromise();

      const endTime = new Date().getTime(); 
      const duration = (endTime - startTime);
      const durationSeg = (duration / 1000).toFixed(3).replace('.', ',');
  
      const tempoDemora = `Tempo de resposta da api: ${durationSeg} segundos`;
      this.tempoDemoraSource.next(tempoDemora);
  
      if (response.status === 200) {
        return response.body.dados.map(dado => ({
          loja: dado.loja,
          codigo: dado.touro,
          nome: dado.nome_de_guerra,
          dataEntrada: dado.dt_entrada,
          pesoEntrada: dado.peso_entrada,
          inicioQuarentena: dado.ini_quarentena,
          prevEntradaColeta: dado.prev_entr_coleta,
          fimQuarentena: dado.fim_quarentena,
          dataSaida: dado.dt_saida,
          ultimaPesagem: dado.ultima_pesagem,
          dataUltimaPesagem: dado.data_ultima_pesagem,
          apenasCentral: (dado.dt_saida == "") || (dado.dt_saida == "  /  /    ") || (dado.dt_saida == "  /  /  ")  || (dado.dt_saida == null),
          status: dado.status_touro
        }));
      } else if (response.status === 204) {
        return []; 
      } else {
        throw new Error(`Erro ao consultar touros. Código: ${response.status}`);
      }
    } catch (error) {
      console.error('Erro ao consultar touros:', error);
      throw error;
    }
  }
  
}
