import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MonitorProducao } from "../models/monitor-producao";
import { AppGlobals } from "src/app/app.globals";
import { AuthService } from "src/app/auth.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MonitorProducaoService {

  private tempoDemoraSource = new BehaviorSubject<string>('');
  tempoDemora$ = this.tempoDemoraSource.asObservable(); 

  constructor(private http: HttpClient, private authService: AuthService) {}

  async getHeadersAPI(usuario: string, dtaIni: string, dtaFin: string): Promise<HttpHeaders> {
    await this.authService.getAccessToken();

    const dataInicialFormatada = AppGlobals.formatDateString(dtaIni);
    const dataFinalFormatada = AppGlobals.formatDateString(dtaFin);

    AppGlobals.exibindoLogs(usuario);
    AppGlobals.exibindoLogs(dtaIni);
    AppGlobals.exibindoLogs(dtaFin);
    AppGlobals.exibindoLogs(AppGlobals.AUTH_TOKEN);

    return new HttpHeaders({
      'code': usuario,
      'tenantid': AppGlobals.TENANT_ID,
      'Authorization': AppGlobals.AUTH_TOKEN,
      'from': dataInicialFormatada,
      'to': dataFinalFormatada
    });
  }

  async consultarMonitorProducao(dtaIni: string, dtaFin: string): Promise<MonitorProducao[]> {
    try {
      const usuario = localStorage.getItem('authUsuario');
      AppGlobals.exibindoLogs("Monitor Producao Service Usuario: " + usuario);

      const headers = await this.getHeadersAPI(usuario, dtaIni, dtaFin);

      AppGlobals.exibindoLogs('Headers: ' + headers.keys().map(key => `${key}: ${headers.get(key)}` + " - "));

      const urlApi = `${AppGlobals.API_URL}/rest/meustouros/v1/touro/:cliente/MonitorProducao`;
      AppGlobals.exibindoLogs("Monitor Producao Service URL Final: " + urlApi);

      const startTime = new Date().getTime();

      const response = await this.http.get<{ code: number, message: string, dados: any[] }>(urlApi, { headers, observe: 'response' }).toPromise();

      const endTime = new Date().getTime(); 
      const duration = (endTime - startTime);
      const durationSeg = (duration / 1000).toFixed(3).replace('.', ',');
  
      const tempoDemora = `Tempo de resposta da api: ${durationSeg} segundos`;
      this.tempoDemoraSource.next(tempoDemora);

      AppGlobals.exibindoLogs("Monitor Producao Resposta");
      AppGlobals.exibindoLogs("Monitor Producao Status Code: " + response.status);

      if (response.status === 200) {
        return response.body.dados.map(dado => ({
          loja: dado.loja,
          contrato: dado.contrato,
          codigoTouro: dado.touro,
          nomeTouro: dado.nome_guerra,
          tipoSemen: dado.tipo_semen,
          raca: dado.raça,
          dataColeta: AppGlobals.stringToDate(dado.dt_coleta),
          partida: dado.partida,
          motil: dado.motil,
          vigor: dado.vigr,
          conc: dado.conc,
          totalDefMai: dado.total_def_mai,
          totalDefMen: dado.total_def_men,
          totalAnor: dado.total_anor,
          status: dado.status,
          dsProdEstoque: dado.ds_prod_esqt,
          dataEntradaEstoque: dado.dt_ent_estq,
          motivo_descarte: dado.motivo_descarte
        }));
      } else if (response.status === 204) {
        return [];
      } else {
        throw new Error(`Erro ao consultar Monitor Producao. Código: ${response.status}`);
      }
    } catch (error) {
      console.error('Erro ao consultar Monitor Producao:', error);
      throw error;
    }
  }
}
