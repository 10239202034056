import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.globals';
import { AuthService } from 'src/app/auth.service';
import { ChartConfiguration, ChartType, ChartOptions } from 'chart.js';
import { TourosService } from 'src/app/core/services/touros.service';
import { ContratosService } from 'src/app/core/services/contratos.service';
import { MonitorProducaoService } from 'src/app/core/services/monitor-producao.service';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import {Chart} from 'chart.js';

registerLocaleData(localePt);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }]
})

export class HomeComponent implements OnInit {
  usuario: string = '';
  senha: string = '';
  isLoading: boolean = false;
  strDetalheLoad: string = 'Carregando Dashboard';

  tourosCentral: number = 0;
  contratosAtivos: number = 0;
  contratosEncerrado: number = 0;

  contConvencialProd: number = 0;
  contSMachoProd: number = 0;
  contSFemeaProd: number = 0;

  constructor(private authService: AuthService, private router: Router, private tourosService: TourosService,
    private contratosService: ContratosService, private monitorProducaoService: MonitorProducaoService
  ) { }

  // Configurações do Gráfico de Barras - Gestão de Constrato
  public gestaoContratoOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#000',
        font: { size: 12 },
        formatter: (value: number) => {
          // Função para formatar o número no padrão brasileiro
          return value.toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      },
      legend: {
        position: 'bottom'
      }
    },
    layout: {
      padding: {
        top: 20, // Espaçamento de 20px acima do gráfico
        bottom: 20 // Espaçamento de 20px abaixo do gráfico
      }
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        beginAtZero: true,
      }
    }
  };
  public gestaoContratoType: ChartType = 'bar';
  public gestaoContratoLabels: string[] = [];
  public gestaoContratoData = {
    labels: this.gestaoContratoLabels,
    datasets: []
  };

  // Configurações do Gráfico de Barras - Monitor de Produção

  public monitorProducaoOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#000',
        font: { size: 12 },
        formatter: (value: number) => {
          // Função para formatar o número no padrão brasileiro
          return value.toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      },
      legend: {
        position: 'bottom'
      }
    },
    layout: {
      padding: {
        top: 20, // Espaçamento de 20px acima do gráfico
        bottom: 20 // Espaçamento de 20px abaixo do gráfico
      }
    },
    scales: {
      x: {
        stacked: true,
      },
    }
  };
  public monitorProducaoType: ChartType = 'bar';
  public monitorProducaoLabels: string[] = [];
  public monitorProducaoData = {
    labels: this.monitorProducaoLabels,
    datasets: []
  };

  // Configurações do Gráfico de Barras - Monitor de Doses

  public dosesOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#000',
        font: { size: 12 },
        formatter: (value: number) => {
          // Função para formatar o número no padrão brasileiro
          return value.toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      },
      legend: {
        position: 'bottom'
      }
    },
    layout: {
      padding: {
        top: 20, // Espaçamento de 20px acima do gráfico
        bottom: 20 // Espaçamento de 20px abaixo do gráfico
      }
    },
    scales: {
      x: {
        stacked: true,
      },
    }
  };
  public dosesType: ChartType = 'bar';
  public dosesLabels: string[] = [];
  public dosesData = {
    labels: this.dosesLabels,
    datasets: []
  };


  // Configurações do Gráfico de Pizza - Localização dos Touros
  public localizacaoOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#000',
        font: { size: 12 },
        formatter: (value: number) => {
          // Função para formatar o número no padrão brasileiro
          return value.toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      },
      legend: {
        position: 'bottom'
      }
    },
    layout: {
      padding: {
        top: 20, // Espaçamento de 20px acima do gráfico
        bottom: 20 // Espaçamento de 20px abaixo do gráfico
      }
    },
    maintainAspectRatio: false,
  };
  public localizacaoType: ChartType = 'pie';
  public localizacaoLabels: string[] = [];
  public localizacaoData = {
    labels: this.localizacaoLabels,
    datasets: [
      {
        data: [100],
        backgroundColor: ['#36A2EB'],
        hoverBackgroundColor: ['#36A2EB'],
      }
    ]
  };


  async ngOnInit(): Promise<void> {

    this.isLoading = true;

    Chart.register(DataLabelsPlugin);

    await this.authService.getAccessToken();

    const usuario = localStorage.getItem('authUsuario');
    const senha = localStorage.getItem('authSenha');
    const nome = localStorage.getItem('authNome');
    const cnpj = localStorage.getItem('authCNPJ');

    AppGlobals.exibindoLogs("Token Usuario: " + usuario);
    AppGlobals.exibindoLogs("Token Senha: " + senha);
    AppGlobals.exibindoLogs("Token Nome: " + nome);
    AppGlobals.exibindoLogs("Token CNPJ: " + cnpj);

    // Carregando Grafico - Meus Touros

    this.strDetalheLoad = 'Carregando dados dos Touros...';
    const touros = await this.tourosService.consultarTouros();

    this.tourosCentral = touros.filter(x => x.apenasCentral).reduce((acc) => { return acc + 1; }, 0);

    // Configurações do Gráfico de Pizza - Localização dos Touros

    const localizacaoQuarentena = touros.filter(x => x.status == "Quarentena" && x.apenasCentral).reduce((acc) => { return acc + 1; }, 0);
    const localizacaoColeta = touros.filter(x => x.status == "Coleta" && x.apenasCentral).reduce((acc) => { return acc + 1; }, 0);
    const localizacaoSantdBy = touros.filter(x => x.status == "Standby" && x.apenasCentral).reduce((acc) => { return acc + 1; }, 0);

    this.localizacaoLabels = ['Quarentena', 'Coleta', 'Standby'];
    this.localizacaoData = {
      labels: this.localizacaoLabels,
      datasets: [
        {
          data: [localizacaoQuarentena, localizacaoColeta, localizacaoSantdBy],
          backgroundColor: ['#004684', '#98012e', '#C0C0C0'],
          hoverBackgroundColor: ['#004684', '#98012e', '#C0C0C0'],
        }
      ]
    };

    // Carregando Grafico de Barras - Gestão de Contratos

    this.strDetalheLoad = 'Carregando dados dos Contratos...';
    const contratos = await this.contratosService.consultarContratos();

    this.contratosAtivos = contratos.filter(x => x.contrato_ativo == true).reduce((acc) => { return acc + 1; }, 0);
    this.contratosEncerrado = contratos.filter(x => x.contrato_ativo == false).reduce((acc) => { return acc + 1; }, 0);

    this.contConvencialProd = contratos.filter(x => x.tipoSemen == "Convencional").reduce((acc, contrato) => { return acc + (Number(contrato.quantidadeProduzida)); }, 0);
    this.contSMachoProd = contratos.filter(x => x.tipoSemen == "Sexado Macho").reduce((acc, contrato) => { return acc + (Number(contrato.quantidadeProduzida)); }, 0);
    this.contSFemeaProd = contratos.filter(x => x.tipoSemen == "Sexado Femea").reduce((acc, contrato) => { return acc + (Number(contrato.quantidadeProduzida)); }, 0);

    // Carregando Grafico de Barras - Doses

    const dosesTotal = contratos.reduce((acc, contrato) => { return acc + (Number(contrato.quantidadeContrato)); }, 0);
    const dosesProduzidas = contratos.reduce((acc, contrato) => { return acc + (Number(contrato.quantidadeProduzida)); }, 0);
    let dosesAProduzir = dosesTotal - dosesProduzidas;
    if (dosesAProduzir < 0) {
      dosesAProduzir = 0;
    }

    this.dosesLabels = ['Doses Produzidas', 'Doses a Produzir'];
    this.dosesData = {
      labels: this.dosesLabels,
      datasets: [
        { data: [dosesProduzidas, dosesAProduzir], label: 'Total', backgroundColor: '#004684' },
      ]
    };

    // Carregando Gráfico de Barras - Monitor de Produção

    this.strDetalheLoad = 'Carregando dados do Monitor de Produção...';
    // Data de hoje
    const hoje = new Date();

    // Data de hoje menos 30 dias
    const dataMenos30Dias = new Date(hoje);
    dataMenos30Dias.setDate(hoje.getDate() - 30);

    // Data de hoje menos 31 dias
    const dataMenos31Dias = new Date(hoje);
    dataMenos31Dias.setDate(hoje.getDate() - 31);

    // Data de hoje menos 60 dias
    const dataMenos60Dias = new Date(hoje);
    dataMenos60Dias.setDate(hoje.getDate() - 60);

    // Data de hoje menos 61 dias
    const dataMenos61Dias = new Date(hoje);
    dataMenos61Dias.setDate(hoje.getDate() - 61);

    // Data de hoje menos 90 dias
    const dataMenos90Dias = new Date(hoje);
    dataMenos90Dias.setDate(hoje.getDate() - 90);


    const monitor = await this.monitorProducaoService.consultarMonitorProducao(dataMenos90Dias.toISOString().split('T')[0], hoje.toISOString().split('T')[0]);

    const monProducaoAtu = monitor
      .filter(x => x.dataColeta >= dataMenos30Dias && x.dataColeta <= hoje)
      .reduce((acc, monitor) => { return acc + (Number(monitor.dsProdEstoque)); }, 0);

    const monProducao30 = monitor
      .filter(x => x.dataColeta >= dataMenos60Dias && x.dataColeta <= dataMenos31Dias)
      .reduce((acc, monitor) => { return acc + (Number(monitor.dsProdEstoque)); }, 0);

    const monProducao60 = monitor
      .filter(x => x.dataColeta >= dataMenos90Dias && x.dataColeta <= dataMenos61Dias)
      .reduce((acc, monitor) => { return acc + (Number(monitor.dsProdEstoque)); }, 0);


    const dataMenos30DiasFormatada = dataMenos30Dias.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const hojeFormatado = hoje.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    const texto1 = `${dataMenos30DiasFormatada} até ${hojeFormatado}.`;


    const dataMenos31DiasFormatada = dataMenos31Dias.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const dataMenos60DiasFormatado = dataMenos60Dias.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    const texto2 = `${dataMenos60DiasFormatado} até ${dataMenos31DiasFormatada}.`;


    const dataMenos61DiasFormatada = dataMenos61Dias.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const dataMenos90DiasFormatado = dataMenos90Dias.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    const texto3 = `${dataMenos90DiasFormatado} até ${dataMenos61DiasFormatada}.`;

    this.monitorProducaoLabels = [`Ult. 30 Dias`, `De 31 a 60 Dias`, `De 61 a 90 Dias`];
    this.monitorProducaoData = {
      labels: this.monitorProducaoLabels,
      datasets: [
        { data: [monProducaoAtu, monProducao30, monProducao60], label: 'Produzido', backgroundColor: '#004684' },
      ]
    };

    this.isLoading = false;

  }
}
