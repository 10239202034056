import jsPDF from "jspdf";

export class AppGerarPDF {

  static intInicioPagina: number = 10;

  static gerarPDF_AdicionandoTitulo(doc: jsPDF, strTitulo: string) {
    doc.setFont('Arial', 'bold');
    doc.setFontSize(12);

    const pageWidth = doc.internal.pageSize.getWidth();
    const titleWidth = doc.getTextWidth(strTitulo);
    const titleX = (pageWidth - titleWidth) / 2;
    doc.text(strTitulo, titleX, 20,);

    var img = new Image()
    img.src = 'assets/icon/icon.png'
    doc.addImage(img, 'PNG', this.intInicioPagina, 10, 15, 15);

  }

  static gerarPDF_AdicionandoHeader(doc: jsPDF, intInicioY: number, alturaLinha: number, columnWidths: number[], headers: string[]) {
    doc.setFont('Arial', 'bold');
    doc.setFontSize(8);

    headers.forEach((header, index) => {
      doc.text(header, this.intInicioPagina + columnWidths.slice(0, index).reduce((a, b) => a + b, 0), intInicioY);
    });

    const lineY = intInicioY + 1;
    doc.setDrawColor(0);
    doc.setLineWidth(0.5);
    doc.line(this.intInicioPagina, lineY, this.intInicioPagina + columnWidths.reduce((a, b) => a + b, 0), lineY);
  }

  static gerarPDF_AdicionandoRodape(doc: jsPDF, columnWidths: number[]) {
    let pageCount = doc.internal.pages.length;
    for (let i = 1; i <= pageCount - 1; i++) {
      doc.setPage(i);

      const lineZ = doc.internal.pageSize.getHeight() - 14;
      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.line(this.intInicioPagina, lineZ, this.intInicioPagina + columnWidths.reduce((a, b) => a + b, 0), lineZ);
      doc.text('Portal Bela Vista - Dono do Touro', this.intInicioPagina, doc.internal.pageSize.getHeight() - 10);
      doc.text(new Date().toLocaleDateString(), 100, doc.internal.pageSize.getHeight() - 10);
      const pageNumberText = `Página ${i}`;
      doc.text(pageNumberText, 160, doc.internal.pageSize.getHeight() - 10);
    }
  }

  static gerarPDF_RetornaTamanho(columnWidths: number[], intPosicao: number) {
    return columnWidths.slice(0, intPosicao).reduce((acc, valor) => {
      return acc + (Number(valor) || 0);
    }, 0);
  }


  static gerarPDF_AdicionandoDados(doc: jsPDF, intInicioY: number, alturaLinha: number, qtdLinPrimeira: number, qtdLinhaApos: number,
    columnWidths: number[], headers: string[], arrayT: any[], propriedades: string[], totalizadoresHeader: string[] = []) {

    let qtdLinhas = 0;
    let paginaAtual = 1;
    let inicioNovo = intInicioY;
    let qtdLinPri = qtdLinPrimeira;

    doc.setFont('Arial', 'normal');
    doc.setFontSize(8);

    if (totalizadoresHeader.length > 0) {

      doc.setFont('Arial', 'bold');
      doc.setFontSize(8); 

      totalizadoresHeader.forEach((t) => {
        doc.text(t, 14, inicioNovo);
  
        inicioNovo = inicioNovo + alturaLinha;
        qtdLinPri = qtdLinPri - 1;
  
      });

      inicioNovo = inicioNovo + alturaLinha;
      qtdLinPri = qtdLinPri - 1;
    }

    this.gerarPDF_AdicionandoHeader(doc, inicioNovo, alturaLinha, columnWidths, headers);

    doc.setFont('Arial', 'normal');
    doc.setFontSize(8);

    arrayT.forEach((t) => {
      const y = inicioNovo + (qtdLinhas + 1) * alturaLinha;
      propriedades.forEach((propriedade, index) => {
        let valor = t[propriedade];
        const xPos = this.intInicioPagina + this.gerarPDF_RetornaTamanho(columnWidths, index);

        if (valor instanceof Date) {
          const day = valor.getDate().toString().padStart(2, '0');
          const month = (valor.getMonth() + 1).toString().padStart(2, '0');
          const year = valor.getFullYear().toString();
          valor = `${day}/${month}/${year}`;
        }
        else if (typeof valor === 'number') {
          valor = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(valor);
        }

        doc.text(valor ? valor.toString() : '', xPos, y);
      });

      qtdLinhas++;

      if (qtdLinhas == (paginaAtual == 1 ? qtdLinPri : qtdLinhaApos)) {

        doc.addPage();

        qtdLinhas = 0;
        paginaAtual++;
        inicioNovo = intInicioY - 20;

        doc.setPage(paginaAtual);

        this.gerarPDF_AdicionandoHeader(doc, inicioNovo, alturaLinha, columnWidths, headers);

        doc.setFont('Arial', 'normal');
        doc.setFontSize(8);
      }

    });
  }
}
