import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppGlobals } from './app.globals';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const usuario = localStorage.getItem('authUsuario');
    const senha = localStorage.getItem('authSenha');

    AppGlobals.exibindoLogs(usuario);
    AppGlobals.exibindoLogs(senha);

    if (usuario && senha) {
      return true; // Se o token existir, permite a navegação
    } else {
      this.router.navigate(['/login']); // Se não existir, redireciona para login
      return false;
    }
  }
}
