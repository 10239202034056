import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { AppGlobals } from "src/app/app.globals";

@Component({
	standalone: true,
	imports: [CommonModule, FormsModule, RouterModule, IonicModule],
	selector: 'app-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
	items = [
		{ name: 'Início', path: 'home' },
		{ name: 'Meus Touros na Central', path: 'meus-touros' },
		{ name: 'Gestão de Contratos', path: 'gestao-contratos' },
		{ name: 'Monitor de Produção', path: 'monitor-producao' },
		{ name: 'Movimentos de Estoque', path: 'movimento-estoque' },
		{ name: 'Baixar App', path: 'download-app' },
		//{ name: 'Sair', path: 'login' },
	];

	itemsMob = [
		{ name: 'Início', path: 'home' },
		{ name: 'Meus Touros na Central', path: 'meus-touros' },
		{ name: 'Gestão de Contratos', path: 'gestao-contratos' },
		{ name: 'Monitor de Produção', path: 'monitor-producao' },
		{ name: 'Movimentos de Estoque', path: 'movimento-estoque' },
		{ name: 'Baixar App', path: 'download-app' },
		{ name: 'Sair', path: 'login' },
	];

	isDropdownOpen: boolean = false;

	nome: string = '';
	cnpj: string = '';
	sigla: string = '';

	checkboxToggled: boolean = false;

	isDrawerContentVisible: boolean = true; 

	constructor(private router: Router) { }

	async ngOnInit(): Promise<void> {
		this.nome = localStorage.getItem('authNome');
		this.cnpj = localStorage.getItem('authCNPJ');
		this.sigla = localStorage.getItem('authSigla');

		this.checkScreenWidth(); 
  		window.addEventListener('resize', this.checkScreenWidth.bind(this)); 
	}

	checkScreenWidth() {
		this.isDrawerContentVisible = window.innerWidth >= 1000;
	  }

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	toggleDrawer() {
		this.checkboxToggled = !this.checkboxToggled
	}

	logout() {
		this.router.navigate(['/login']);
	  }

	showImg(): boolean {

		if (AppGlobals.PAGE_WID < 700) {
			return false
		}
		else {
			return true;
		}
	}

	downloadApp() {
		const link = document.createElement('a');
		link.href = 'assets/downloads/DonoDoTouro.apk';  
		link.download = 'DonoDoTouro.apk';
		link.click();
	}
}
