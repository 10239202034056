import { CommonModule, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import jsPDF from 'jspdf';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppGerarPDF } from 'src/app/app.gerapdf';
import { AppGlobals } from 'src/app/app.globals';
import { MonitorProducao } from "src/app/core/models/monitor-producao";
import { MonitorProducaoService } from "src/app/core/services/monitor-producao.service";
import { DadosEmpresaComponent } from "../../shared/components/dados-empresa.component";
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, NgxPaginationModule, DadosEmpresaComponent],
  selector: 'app-monitor-producao',
  templateUrl: './monitor-producao.component.html',
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }]
})
export class MonitorProducaoComponent implements OnInit {
  monitor: MonitorProducao[] = [];
  monitorFiltro: MonitorProducao[] = [];
  nomeTouro: string = '';
  tipoSemen: string = '';
  isLoading: boolean = false;
  dtaIni: string = null;
  dtaFin: string = null;
  listaLojas: string[] = [];
  lojaSelecionada: string = 'Todas';
  page: number = 1;
  totalDosesProduzidas: number = 0;
  blnHomologacao: boolean = false;
  tempoDemora: string = "Tempo resposta api: ";

  constructor(private monitorProducaoService: MonitorProducaoService) { }

  async ngOnInit() {
    this.isLoading = true;

    const dtaHoje = new Date();
    this.dtaIni = new Date(dtaHoje.setDate(dtaHoje.getDate() - AppGlobals.DIAS_FILTROS)).toISOString().split('T')[0];
    this.dtaFin = new Date().toISOString().split('T')[0];

    this.monitorProducaoService.tempoDemora$.subscribe((tempo) => {
      this.tempoDemora = tempo;
    });

    this.monitor = await this.monitorProducaoService.consultarMonitorProducao(this.dtaIni, this.dtaFin);
    this.monitor.sort((a, b) => {
      const dateA = new Date(a.dataColeta).getTime();
      const dateB = new Date(b.dataColeta).getTime();
      if (dateA !== dateB) {
        return dateB - dateA;
      }

      if (a.contrato !== b.contrato) {
        return a.contrato.localeCompare(b.contrato);
      }

      return a.partida.localeCompare(b.partida);
    });
    
    this.monitorFiltro = this.monitor;

    this.calcularTotalDoses();

    this.listaLojas = [...new Set(this.monitor.map(gestao => gestao.loja))].sort();

    this.blnHomologacao = AppGlobals.blnHomologacao;

    this.isLoading = false;
  }

  calcularTotalDoses() {
    this.totalDosesProduzidas = this.monitorFiltro.reduce((acc, monitor) => {
      return acc + (Number(monitor.dsProdEstoque) || 0);
    }, 0);
  }

  async filtrarProducaoData() {
    this.isLoading = true;

    this.monitorProducaoService.tempoDemora$.subscribe((tempo) => {
      this.tempoDemora = tempo;
    });

    this.monitor = await this.monitorProducaoService.consultarMonitorProducao(this.dtaIni, this.dtaFin);
    this.monitorFiltro = this.monitor;

    await this.filtrarProducao();

    this.calcularTotalDoses();

    this.page = 1;

    this.isLoading = false;
  }

  filtrarProducao() {
    this.isLoading = true;

    setTimeout(() => {
      this.monitorFiltro = this.monitor;

      if (this.nomeTouro) {
        this.monitorFiltro = this.monitorFiltro.filter(x => x.nomeTouro.toUpperCase().includes(this.nomeTouro.toUpperCase()));
      }

      if (this.tipoSemen) {
        this.monitorFiltro = this.monitorFiltro.filter(x => x.tipoSemen.toUpperCase().includes(this.tipoSemen.toUpperCase()));
      }

      if (this.lojaSelecionada != "" && this.lojaSelecionada != "Todas") {
        this.monitorFiltro = this.monitorFiltro.filter(x => x.loja === this.lojaSelecionada);
      }

      this.calcularTotalDoses();

      this.page = 1;

      this.isLoading = false;
    }, 500);
  }

  gerarPDF() {

    if (this.monitorFiltro.length == 0)
      return;

    const doc = new jsPDF('landscape');
    const intInicioY = 30;
    const alturaLinha = 5;
    const tituloRelatorio = 'Monitor de Produção';

    const headers = [
      'Contrato', 'Touro', 'Nome Guerra', 'Tipo Semen', 'Raça',
      'Dt.Coleta', 'Partida', 'Motil.', 'Vigor', 'Conc.',
      'Def.Mai', 'Def.Men.', 'Anor', 'Status', 'Ds. Prod. Estq.',
      'Dt.Ent.Estq.'
    ];

    const propriedades = [
      'contrato', 'codigoTouro', 'nomeTouro', 'tipoSemen', 'raca',
      'dataColeta', 'partida', 'motil', 'vigor', 'conc',
      'totalDefMai', 'totalDefMen', 'totalAnor', 'status', 'dsProdEstoque',
      'dataEntradaEstoque'
    ];

    const totalizadoresHeader = [
      'Total de Doses Produzidas: ' + this.totalDosesProduzidas.toFixed(2)
    ]

    const columnWidths = [
      22, 18, 45, 22, 10,
      16, 16, 14, 12, 12,
      12, 12, 12, 12, 20,
      20
    ];

    const totalItensPrim = 33;
    const totalItensProx = 37;

    AppGerarPDF.gerarPDF_AdicionandoTitulo(doc, tituloRelatorio);
    AppGerarPDF.gerarPDF_AdicionandoDados(doc, intInicioY, alturaLinha, totalItensPrim, totalItensProx, columnWidths, headers, this.monitorFiltro, propriedades, totalizadoresHeader);
    AppGerarPDF.gerarPDF_AdicionandoRodape(doc, columnWidths);

    doc.save('MonitorProducao.pdf');
  }

}
