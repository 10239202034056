// src/app/globals/app.globals.ts
export class AppGlobals {
    public static readonly EXB_CONSOLE_LOG: boolean = false;
    public static readonly DIAS_FILTROS: number = 90;
    public static readonly API_URL: string = 'http://200.178.138.135:8105';
    public static readonly TENANT_ID: string = '02,01';
    public static STORE: string = '';
    public static AUTH_TOKEN: string = '';
    public static PAGE_WID: number = 0;
    public static DataHoraBearer: Date;
    public static readonly blnHomologacao: boolean = true;
    
    static exibindoLogs(log: string): void {
      if (AppGlobals.EXB_CONSOLE_LOG){
        console.log(log);
      }
    }

    static stringToDate(dateStr: string): Date | null {
      
      const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
      if (!datePattern.test(dateStr)) {
        return null;
      }
    
      const [day, month, year] = dateStr.split('/').map(part => parseInt(part, 10));
      const date = new Date(year, month - 1, day);
    
      if (
        date.getFullYear() !== year || 
        date.getMonth() !== month - 1 || 
        date.getDate() !== day
      ) {
        return null;
      }
    
      return date;
    }

    static formatDateString(dateString: string): string {
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
    }

    static formatarDataInput(data: Date): string {
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0'); 
      const ano = data.getFullYear();
      return `${ano}-${mes}-${dia}`;
    }

    static fRetornaTipoSemen(input: string): string {
      switch (input) {
        case 'CV':
          return 'Convencional';
        case 'SM':
          return 'Sexado Macho';
        case 'SF':
          return 'Sexado Fêmea';
        default:
          return '';
      }
    }

    static fRetornaNomePalheta(input: string): string {
      switch (input) {
        case 'F':
          return 'Fina';
        case 'M':
          return 'Média';
        default:
          return '';
      }
    }

    static fRetornaNomeDestino(input: string): string {
      switch (input) {
        case '01':
          return 'Industrialização';
        case '06':
          return 'Outros';
        default:
          return '';
      }
    }

    static simplificarNome(texto: string): string {
      const ignorarPalavras = ['DE', 'DA', 'DO', 'DAS', 'DOS', 'LTDA', 'E', 'ME', 'PP'];
    
      const palavras = texto
        .toUpperCase()
        .split(' ')
        .filter(palavra => !ignorarPalavras.includes(palavra)); 
    
      if (palavras.length === 0) {
        return 'DT'; 
      }
    
      const primeiraLetra = palavras[0].charAt(0);
      const ultimaLetra = palavras[palavras.length - 1].charAt(0);
    
      return primeiraLetra + ultimaLetra;
    }

    static strToNumber(value: string): number {
      let convertedNumber = Number(value);
    
      if (isNaN(convertedNumber)) {
        convertedNumber = 0; 
      }
    
      return convertedNumber;
    }
  }
  