import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Contrato } from "../models/contrato";
import { AppGlobals } from "src/app/app.globals";
import { AuthService } from "src/app/auth.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContratosService {

  private tempoDemoraSource = new BehaviorSubject<string>('');
  tempoDemora$ = this.tempoDemoraSource.asObservable(); 

  constructor(private http: HttpClient, private authService: AuthService) { }

  async getHeadersAPI(usuario: string): Promise<HttpHeaders> {
    await this.authService.getAccessToken();

    return new HttpHeaders({
      'code': usuario,
      //'code': "00028501",
      'tenantid': AppGlobals.TENANT_ID,
      'Authorization': AppGlobals.AUTH_TOKEN
    });
  }

  async consultarContratos(): Promise<Contrato[]> {
    try {
      const usuario = localStorage.getItem('authUsuario');
      AppGlobals.exibindoLogs("Contrato Service Usuario: " + usuario);

      const headers = await this.getHeadersAPI(usuario);

      const urlApi = AppGlobals.API_URL + "/rest/meustouros/v1/touro/:cliente/contratos";
      AppGlobals.exibindoLogs("Contrato Service URL Final: " + urlApi);

      const startTime = new Date().getTime();

      const response = await this.http.get<{ code: number, message: string, dados: any[] }>(urlApi, { headers: headers, observe: 'response' }).toPromise();

      const endTime = new Date().getTime(); 
      const duration = (endTime - startTime);
      const durationSeg = (duration / 1000).toFixed(3).replace('.', ',');
  
      const tempoDemora = `Tempo de resposta da api: ${durationSeg} segundos`;
      this.tempoDemoraSource.next(tempoDemora);

      AppGlobals.exibindoLogs("Contrato Service Resposta");
      AppGlobals.exibindoLogs("Contrato Service Status Code: " + response.status);

      if (response.status === 200) {

        return response.body.dados.map(dado => {

          const qtdContrato = AppGlobals.strToNumber(dado.qtd_tot_contrato);
          const qtdProduzido = AppGlobals.strToNumber(dado.qtd_produzida);
          
          const emAbertoQtd = qtdContrato - qtdProduzido;
          const emAbertoPerc = Math.max((emAbertoQtd / qtdContrato) * 100);
          const prdMais = Math.max((qtdProduzido / qtdContrato) * 100);

          let strFalta = "";

          if (qtdContrato >= qtdProduzido) {
            strFalta = emAbertoPerc.toFixed(2);
          }

          else if (qtdContrato < qtdProduzido) {
            strFalta = "+" + prdMais.toFixed(2);
          }

          return {
            loja: dado.loja,
            codigo: dado.contrato,
            codigoTouro: dado.touro,
            nomeTouro: dado.nome_guerra,
            tipoSemen: dado.tipo_semen,
            destino: dado.destino,
            palheta: dado.palheta,
            dataInicio: dado.dt_inicio,
            dataFim: dado.dt_fim == "  /  /    " ? "" : dado.dt_fim,
            quantidadeContrato: qtdContrato,
            quantidadeProduzida: qtdProduzido,
            emAbertoQtd: emAbertoQtd,
            emAbertoPerc: emAbertoPerc,
            str_falta: strFalta,
            contrato_ativo: dado.contrato_ativo
          };
        });

      } else if (response.status === 204) {
        return [];
      } else {
        throw new Error(`Erro ao consultar contratos. Código: ${response.status}`);
      }
    } catch (error) {
      console.error('Erro ao consultar contratos:', error);
      throw error;
    }
  }

}
