import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MovimentoEstoque } from "../models/movimento-estoque";
import { AppGlobals } from "src/app/app.globals";
import { AuthService } from "src/app/auth.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MovimentoEstoqueService {

  private tempoDemoraSource = new BehaviorSubject<string>('');
  tempoDemora$ = this.tempoDemoraSource.asObservable(); 

  constructor(private http: HttpClient, private authService: AuthService) {}

  async getHeadersAPI(usuario: string, dtaIni: string, dtaFin: string): Promise<HttpHeaders> {
    await this.authService.getAccessToken();

    const dataInicialFormatada = AppGlobals.formatDateString(dtaIni);
    const dataFinalFormatada = AppGlobals.formatDateString(dtaFin);

    AppGlobals.exibindoLogs(usuario);
    AppGlobals.exibindoLogs(dtaIni);
    AppGlobals.exibindoLogs(dtaFin);
    AppGlobals.exibindoLogs(AppGlobals.AUTH_TOKEN);

    return new HttpHeaders({
      'code': usuario,
      'tenantid': AppGlobals.TENANT_ID,
      'Authorization': AppGlobals.AUTH_TOKEN,
      'from': dataInicialFormatada,
      'to': dataFinalFormatada
    });
  }

  async consultarMovimentoEstoque(dtaIni: string, dtaFin: string): Promise<MovimentoEstoque[]> {
    try {
      const usuario = localStorage.getItem('authUsuario');
      AppGlobals.exibindoLogs("Movimento de Estoque Service Usuario: " + usuario);
  
      const headers = await this.getHeadersAPI(usuario, dtaIni, dtaFin);

      AppGlobals.exibindoLogs('Headers: ' + headers.keys().map(key => `${key}: ${headers.get(key)}` + " - "));
  
      const urlApi = AppGlobals.API_URL + "/rest/meustouros/v1/touro/:cliente/movimentosestoque";
      AppGlobals.exibindoLogs("Movimento de Estoque Service URL Final: " + urlApi);

      const startTime = new Date().getTime();
  
      const response = await this.http.get<{ code: number, message: string, dados: any[] }>(urlApi, { headers: headers, observe: 'response' }).toPromise();

      const endTime = new Date().getTime(); 
      const duration = (endTime - startTime);
      const durationSeg = (duration / 1000).toFixed(3).replace('.', ',');
  
      const tempoDemora = `Tempo de resposta da api: ${durationSeg} segundos`;
      this.tempoDemoraSource.next(tempoDemora);
      
      AppGlobals.exibindoLogs("Movimento de Estoque Resposta");
      AppGlobals.exibindoLogs("Movimento de Estoque Status Code: " + response.status);
  
      if (response.status === 200) {
        return response.body.dados.map(dado => ({
          loja: dado.loja,
          contrato: dado.contrato,
          touro: dado.contrato.substring(0, 7),
          operacao: dado.operacao,
          descr_operacao: dado.descr_operacao,
          documento: dado.documento,
          descr_documento: dado.descr_documento,
          data: dado.data,
          dataDate: AppGlobals.stringToDate(dado.data),
          doses: dado.doses,
          nome_guerra: dado.nome_guerra,
          contrato_ativo: dado.contrato_ativo,
          tipo_semen: dado.tipo_semen,
        }));
      } else if (response.status === 204) {
        return []; // Retorna array vazio em caso de 204 (No Content)
      } else {
        throw new Error(`Erro ao consultar Movimento de Estoque. Código: ${response.status}`);
      }
    } catch (error) {
      console.error('Erro ao consultar Movimento de Estoque:', error);
      throw error;
    }
  }
  
  
}
