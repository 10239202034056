import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import jsPDF from 'jspdf';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppGerarPDF } from 'src/app/app.gerapdf';
import { Contrato } from 'src/app/core/models/contrato';
import { ContratosService } from 'src/app/core/services/contratos.service';
import { DadosEmpresaComponent } from "../../shared/components/dados-empresa.component";
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AppGlobals } from 'src/app/app.globals';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, NgxPaginationModule, DadosEmpresaComponent],
  selector: 'app-gestao-contratos',
  templateUrl: './gestao-contratos.component.html',
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }]
})
export class GestaoContratosComponent implements OnInit {
  contratos: Contrato[] = [];
  contratosFiltro: Contrato[] = [];
  nomeTouro: string = '';
  tipoSemen: string = '';
  apenasContratoAbertos: boolean = true;
  isLoading: boolean = false;
  listaLojas: string[] = [];
  lojaSelecionada: string = 'Todas';
  page: number = 1;
  blnHomologacao: boolean = false;
  tempoDemora: string = "Tempo resposta api: ";

  constructor(private contratosService: ContratosService) { }

  async ngOnInit() {
    this.isLoading = true;

    this.contratosService.tempoDemora$.subscribe((tempo) => {
      this.tempoDemora = tempo;
    });

    this.contratos = await this.contratosService.consultarContratos();
    this.contratosFiltro = this.contratos.filter(x => x.contrato_ativo == true);

    this.listaLojas = [...new Set(this.contratos.map(gestao => gestao.loja))].sort();

    this.blnHomologacao = AppGlobals.blnHomologacao;

    this.isLoading = false;
  }

  filtrarContratos() {
    this.contratosFiltro = this.contratos.filter(x => x.nomeTouro.toUpperCase().includes(this.nomeTouro.toUpperCase()));

    if (this.tipoSemen != "")
      this.contratosFiltro = this.contratosFiltro.filter(x => x.tipoSemen.toUpperCase().includes(this.tipoSemen.toUpperCase()));

    if (this.apenasContratoAbertos)
      this.contratosFiltro = this.contratosFiltro.filter(x => x.contrato_ativo == true);

    if (this.lojaSelecionada != "" && this.lojaSelecionada != "Todas") 
      this.contratosFiltro = this.contratosFiltro.filter(x => x.loja == this.lojaSelecionada);

    this.page = 1;
  }

  gerarPDF() {

    if (this.contratosFiltro.length == 0)
      return;

    const doc = new jsPDF('landscape');
    const intInicioY = 30;
    const alturaLinha = 5;
    const tituloRelatorio = 'Gestão de Contratos';
    const headers = ['Contrato', 'Touro', 'Nome Guerra', 'Tipo Sêmen', 'Destino', 'Palheta', 'Dt.Inicio', 'Dt. Fim', 'Qtd.Tot.Cont', 'Qtd.Prod', '% Falta'];
    const propriedades = ['codigo', 'codigoTouro', 'nomeTouro', 'tipoSemen', 'destino', 'palheta', 'dataInicio', 'dataFim', 'quantidadeContrato', 'quantidadeProduzida', 'emAbertoPerc'];
    const columnWidths = [22, 18, 60, 22, 35, 18, 18, 18, 20, 18, 18];
    const totalItensPrim = 33;
    const totalItensProx = 37;

    AppGerarPDF.gerarPDF_AdicionandoTitulo(doc, tituloRelatorio);
    AppGerarPDF.gerarPDF_AdicionandoDados(doc, intInicioY, alturaLinha, totalItensPrim, totalItensProx, columnWidths, headers, this.contratosFiltro, propriedades);
    AppGerarPDF.gerarPDF_AdicionandoRodape(doc, columnWidths);

    doc.save('GestaoContratos.pdf');
  }
}
